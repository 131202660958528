.grid-component.lineItems{
    .grid-component_header.fromDate {
        .ui-grid-cell-contents > span {
            max-width: 90px;
        }
    }
    .lineItems-date-input {
        line-height: initial;

        input {
            padding: 0 3px 0 3px;
            &:not(:focus) {
                border-color: white;
            }
        }
    }
    .ndc-measure_select {
        width: 45%;
    }
    .ndc-quantity_input {
        border: none;
        display: inline-block;
        max-width: 55%;
        &.ng-invalid {
            border: 1px solid $invalid-color;
        }
    }

    .diagnosis-pointers_input {
        display: inline-block;
        &.ng-invalid{
            border: 1px solid $invalid-color;
        }
    }
}

.claim-details--lineItems {
    .heading {
        height: 55px;
        background-color: $claims-grid-bgColor2;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
    }
}

.billReviewHeader {
    color: #A9A9A9;
}

.choose-bill-review-overflow {
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}